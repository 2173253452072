<template>
    <div>
        <v-card >
            <s-toolbar label="Cambios Guias de Cosecha" dark color="#8e8f91" excel @excel="exportExcel()"></s-toolbar>
            <v-container>
                <v-row>
                    <v-col md="5">
                        <s-date label="Fecha Inicio" v-model="filter.cDateInitial"></s-date>
                    </v-col>
                    <v-col md="5">
                        <s-date label="Fecha Fin" v-model="filter.cDateFin"></s-date>
                    </v-col>
                    <v-col md="2" align-self="center">
                        <b style="color:red">Maximo 50 registros</b>
                    </v-col>
                    <!-- <v-btn
                            @click="exportExcel()"
                            class="mt-3 mr-3"
                            small
                            fab
                            color="success"
                            ><i style="font-size:20px;" color="success" class="fas fa-file-excel"></i
						>
                        </v-btn> -->
                </v-row>
            </v-container>
        </v-card>

        <v-card v-for="item in items">
            <v-container>
                <v-row>
                    <h3 class="mt-3 ml-6"> Lote Producción:     {{item.RcfID}} - {{item.PrdCardName}}</h3>
                        <v-spacer> </v-spacer>
                        
                </v-row>

				<v-row>
                    <v-col class="pb-0">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>

                <v-row>

                    <v-col lg="6" class="pt-0">
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b style="color: blue">{{item.RcfNumberHarvestGuide}}</b></v-list-item-title>
                                    <v-list-item-subtitle >Guia Cosecha Actual</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title > {{item.FagName}} - {{item.FltName}}</v-list-item-title>
                                    <v-list-item-subtitle >Fundo /Lote</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title >{{item.FltCodSenasa}}</v-list-item-title>
                                    <v-list-item-subtitle >Cod. Senasa</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            
                        </v-list>
                    </v-col>


                    <v-col lg="6" class="pt-0">
                        <v-list>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title ><b style="color: red">{{item.RcsNumberHarvestGuide}}</b></v-list-item-title>
                                    <v-list-item-subtitle >Guia de Cosecha Anterior</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title >{{item.ZonName}} / {{item.FagAddress}}</v-list-item-title>
                                    <v-list-item-subtitle >Zona / Direccion</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title >{{item.TnrDateBegin}} / {{item.TrnDateEnd}}</v-list-item-title>
                                    <v-list-item-subtitle >Turno Inicio /Fin</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </v-col>
                    

                </v-row>

            </v-container>
        </v-card>

    </div>

</template>

<script>
	import _sReceptionFresh from "../../../services/FreshProduction/ReceptionFreshService";
	export default {
		data() {
			return {
                filter: {},
                items: [],
            };
		},
        watch: {
            'filter.cDateInitial'(){
                this.load()
            },
            'filter.cDateFin'(){
                this.load()
            }
        },
		methods: {
			load() {
                _sReceptionFresh.getAnalysisGuide(this.filter, this.$fun.getUserID()).then(resp => {
                    if(resp.status == 200){
                        
                        resp.data.forEach(element => {
                            element.TnrDateBegin = this.$moment(element.TnrDateBegin).format(this.$const.FormatDateTimeDB);
                            //
                            if(element.TrnDateEnd == null)
                            {
                                const now = new Date()
                                element.TrnDateEnd = this.$moment(now).format(this.$const.FormatDateTimeDB);
                            
                            }else
                            {
                                element.TrnDateEnd = this.$moment(element.TrnDateEnd).format(this.$const.FormatDateTimeDB);
                            }
                        });

                        this.items = resp.data

                        console.log(this.items);
                    }
                })
            },

            exportExcel(){

                console.log("export", this.items);

                if(this.items.length > 0){
                    _sReceptionFresh.exportExcelGuideAnalysis(this.items, this.$fun.getUserID()).then((r) => {
                        if(r.status == 200){
                            this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, this.$fun.getUserID());
                        }
                        
                    });
                }
            }
		},
		created() {this.load()}
	};
</script>

<style>
</style>